<template>
  <div>
    <b-row class="mt-4" no-gutters>
      <b-col>
        <div class="pr-3">
          <div class="p-4 bg-dark-jungle rounded">
            <sidebar-category @click="$router.push({ name: 'ProjectTeammateWizard' })" name="AI Teammates" class="pr-1 mb-1" />

            <sidebar-task
              v-for="t in teammatesSorted()"
              :key="t.id"
              :name="t.name"
              :params="{ teammateId: t.id }"
              to="ProjectTeammate"
              class="mb-1"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BRow, BCol } from "bootstrap-vue";

import SidebarCategory from "@/components/Sidebar/Project/Category.vue";
import SidebarTask from "@/components/Sidebar/Project/Task.vue";

export default {
  name: "Teammates",
  components: { BRow, BCol, SidebarCategory, SidebarTask },
  computed: {
    ...mapGetters("projectsTeammates", ["teammatesSorted"]),
  },
  data: () => ({
    isHovering: false,
  }),
};
</script>
